import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Button, Col, Form, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap'
import api from "../../api/customer";
import {useGlobalStore} from '../../store/globalContext'
import moment from "moment";
import RegisterOtherAgentsPanel from "./RegisterOtherAgentPanel";

export default function EditRegisterCustomer({ data, onSubmitForm, onClose, alertError }) {
    // console.log(data)
    const [{ login_profile }, dispatch] = useGlobalStore();
    const {
        id,
        main_bank_account_no,
        main_bank_short_name,
        sub1_bank_account_no,
        sub1_bank_short_name,
        sub2_bank_account_no,
        sub2_bank_short_name,
        channel_select,
        reference_user_id,
        status,
        agents,
        credit_auto,
        deposit_tier,
        withdraw_tier,
        comment,
        verified,
        created_at,
        note,
        agent_name,
        allow_add_agents,
    } = data

    const vip_tier = (deposit_tier === "vip")

    let agent_username;

    for (let i = 0; i < agents.length; i++) {
        if (agents[i].agent_name === agent_name) {
            agent_username = agents[i].agent_username
            break
        }
    }

    const { register, handleSubmit, watch, errors, setValue} = useForm({
        defaultValues: { ...data, vip_tier: vip_tier, agent_username: agent_username }, criteriaMode: "all"
    });

    // const agentOptions = [
    //     { innerText: "AUTO UFA", value: "ufa_auto" },
    //     { innerText: "AUTO GCLUB", value: "gclub_auto" },
    //     { innerText: "Agent UFA", value: "ufa" },
    //     { innerText: "Agent GCLUB", value: "gclub" }];

    const [bankOptions, setBankOptions] = useState([]);
    const [agentIDOptions, setAgentIDOption] = useState([])
    const [buttonIsLoading, setButtonIsLoading] = useState(false)
    const [retryFlag, setRetryFlag] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [otherAgentId, setOtherAgentId] = useState('')

    useEffect(()=>{
        if (alertError !== ""){
            setButtonIsLoading(false)
        }
    },[alertError])

    useEffect(() => {
        //console.log("login_profile :", login_profile.role)
        const getBankList = async () => {
            try {
                const response = await api.GET_THAI_BANK_API();
                const { data } = response.data
                //console.log("bank_list_data: ", data)
                let dropdownBankList = []
                data.forEach(key => {
                    dropdownBankList.push({ innerText: key.text, value: key.value, code: key.code })
                })
                setBankOptions(dropdownBankList);
                //console.log(response.data.data);


            } catch (error) {
                //console.log("get bank list error : ", error);
                return
            }
        }

        const getAgentIDList = async () => {
            try {
                const response = await api.GET_AGENT_LIST();
                // console.log("response: ", response)
                if (response.status !== 200) {
                    //console.log("get agent list failed");
                    return
                }

                const { data } = response.data
                // console.log("agent list data: ", data)
                let dropdownAgentList = []
                if(data){
                    data.forEach(key => {
                        dropdownAgentList.push({
                            agentName: key.agent_name,
                            ufaAgentId: key.ufa_agent_id,
                            gclubAgentId: key.gclub_agent_id })
                    })
                    //console.log("bank options data: ", data)
                    setAgentIDOption(dropdownAgentList);
                }

                //console.log(response.data.data);
            } catch (error) {
                console.log('get agent id list error : ', error)
                return
            }
        }

        void getBankList();
        void getAgentIDList();
    }, [])

    const channelOptions = [
        { innerText: "Google", value: "google" },
        { innerText: "Facebook", value: "facebook" },
        { innerText: "Twitter", value: "twitter" },
        { innerText: "Youtube", value: "youtube" },
        { innerText: "TikTok", value: "tiktok" },
        { innerText: "Friends", value: "friends" }]

    const [validated, setValidated] = useState(false);

    const onSubmit = editData => {
        setButtonIsLoading(true)

        // console.log("update data: ", editData)
        // console.log("deposit_tier: ", deposit_tier)
        // console.log("withdraw_tier: ", withdraw_tier)

        const vipTierWatch = watch("vip_tier")
        let depositTier = deposit_tier
        let withdrawTier = withdraw_tier

        if (vip_tier !== vipTierWatch){
             depositTier = vipTierWatch === true ? "vip" : "normal"
             withdrawTier = vipTierWatch === true ? "vip" : "normal"
        }

        const editDataWithStatus = {
            ...editData,
            status: data.status,
            updated_by: login_profile.name,
            id: id,
            retry_flag: retryFlag,
            deposit_tier: depositTier,
            withdraw_tier: withdrawTier,
            bank_accounts: [
                {
                    "bank_short_name": editData.main_bank_short_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === editData.main_bank_short_name)?.innerText,
                    "bank_account_no": editData.main_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === editData.main_bank_short_name)?.code,
                    "is_main_bank_account": true,
                }
            ]
        }

        if (editData.sub1_bank_account_name !== "") {
            editDataWithStatus.bank_accounts.push(
                {
                    "bank_short_name": editData.sub1_bank_short_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === editData.sub1_bank_short_name)?.innerText,
                    "bank_account_no": editData.sub1_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === editData.sub1_bank_short_name)?.code,
                    "is_main_bank_account": false,
                })
        }

        if (editData.sub2_bank_account_no !== "") {
            editDataWithStatus.bank_accounts.push(
                {
                    "bank_short_name": editData.sub2_bank_short_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === editData.sub2_bank_short_name)?.innerText,
                    "bank_account_no": editData.sub2_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === editData.sub2_bank_short_name)?.code,
                    "is_main_bank_account": false,
                }
            )
        }
        //console.log("editDataWithStatus :", editDataWithStatus)

        onSubmitForm(
            { updateCustomer: editDataWithStatus }
        );
    };

    // const [registerOtherAgent, setRegisterOtherAgent] = useState({ register_agent_flag: false, button_agent: "ufa เพิ่ม", register_agent: "ufa_auto" })

    // useEffect(() => {
    //     if (agents.length === 2) {
    //         console.log("set false :", agents.length)
    //         setRegisterOtherAgent({ register_agent_flag: false, button_agent: "ไม่เพิ่ม" })
    //         return
    //     }
    //
    //     agents.map((e) => {
    //         if (e.agent_name === "ufa") {
    //             setRegisterOtherAgent({ register_agent_flag: true, button_agent: "gclub เพิ่ม", register_agent: "gclub_auto" })
    //         } else {
    //             setRegisterOtherAgent({ register_agent_flag: true, button_agent: "ufa เพิ่ม", register_agent: "ufa_auto" })
    //         }
    //     })
    // }, [])

    // const handleRegisterOtherAgent = async () => {
    //     //console.log("register other agent id ", id)
    //     setButtonIsLoading(true)
    //     const request_register_other_agent = {
    //         id: id,
    //         agent_name: registerOtherAgent.register_agent,
    //         agent_id: otherAgentId,
    //     }
    //
    //     try {
    //         const response = await api.REGISTER_OTHER_AGENT_CUSTOMER(request_register_other_agent);
    //         setButtonIsLoading(false)
    //         // console.log("get bank list response", { ...response });
    //         //console.log(response.data.data);
    //         if (response.status !== 200) {
    //             console.log("register other agent failed: ", response.data.data);
    //             return
    //         }
    //         const { data } = response.data
    //         //console.log("register other agent success: ", data);
    //         dispatch({
    //             type: "TOAST2",
    //             value: {
    //                 show: true,
    //                 type: 'success',
    //                 title: `สมัคร agent ${registerOtherAgent.register_agent}`,
    //                 message: `สมัคร agent ${registerOtherAgent.register_agent} สำเร็จ`,
    //             }
    //         });
    //         callbackSearch(id);
    //         onClose();
    //         return
    //     } catch (error) {
    //         console.log("register other agent error: ", error);
    //         onClose();
    //         return
    //     }
    // }

    const renderButtonPanel = (status) =>{
        if(status === "failed"){
            return(
                <Button type="submit" variant="success" onClick={()=> setRetryFlag(true)} disabled={disableButton}>
                    ลองสมัครอีกครั้ง
                </Button>
            )
        }else if (status === "waiting"){
            return(
                <></>
            )
        }else{
            return(
                <Button type="submit" variant="primary" disabled={disableButton}>
                    บันทึกการแก้ไข
                </Button>
            )
        }
    }

    const getBankSelectFromRole = (bank_short_name, option, role) =>{
        let options = bankOptions;
        if (option === "sub1_bank_short_name") {
            options = bankOptions.filter((bank) => bank.value !== watch("main_bank_short_name"))
        } else if (option === "sub2_bank_short_name") {
            options = bankOptions.filter((bank) => (bank.value !== watch("main_bank_short_name") && bank.value !== watch("sub1_bank_short_name")))
        }
        // console.log("bank_short_name: ", bank_short_name)
        if (role === "normal" && bank_short_name !== undefined){
            const bankSelected =  bankOptions.filter((bank) => bank.value === bank_short_name)
            return (
                bankSelected.map(({ innerText, value }, index) =>
                    <option
                        key={index}
                        value={value}
                        selected={value === bank_short_name ? true : false}
                        onChange={
                            ()=>setValue(bank_short_name, bank_short_name)
                        }
                    >
                        {innerText}
                    </option>
                ))
        }else{
            return (
                options.map(({ innerText, value }, index) =>
                    <option
                        key={index}
                        value={value}
                        selected={value === bank_short_name ? true : false}
                        onChange={
                            ()=>setValue(bank_short_name, bank_short_name)
                        }
                    >
                        {innerText}
                    </option>
                ))
        }
    }

    const selectAgentList = () => {
        if (agents === null || agents === undefined || agents.length === 0) {
            return
        }

        if (agents.length === 2 || agentIDOptions.length === 0) {
            return
        }

        if (agents[0].agent_name === "ufa") {
            return (
                <>
                    <Form.Label>เลือก Agent ที่ต้องการสมัครเพิ่ม</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="agent_id"
                                  defaultValue=""
                                  isInvalid={!!errors.agent_id}
                                  custom
                                  onChange={(e) => setOtherAgentId(e.target.value)}
                    >
                        <option value="">Please Select</option>
                        {
                            agentIDOptions.map(({ agentName, gclubAgentId }, index) =>
                                <option
                                    key={index}
                                    value={gclubAgentId}
                                >
                                    {agentName}
                                </option>
                            )
                        }
                    </Form.Control>
                </>
            )
        } else {
            return (
                <>
                    <Form.Label>เลือก Agent ที่ต้องการสมัครเพิ่ม</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="agent_id"
                                  defaultValue=""
                                  isInvalid={!!errors.agent_id}
                                  custom
                                  onChange={(e) => setOtherAgentId(e.target.value)}
                    >
                        <option value="">Please Select</option>
                        {
                            agentIDOptions.map(({ agentName, ufaAgentId }, index) =>
                                <option
                                    key={index}
                                    value={ufaAgentId}
                                >
                                    {agentName}
                                </option>
                            )
                        }
                    </Form.Control>
                </>
            )
        }

    }

    // useEffect(()=>{
    //     const vipTierWatch = watch("vip_tier")
    //     console.log("vip_tier: ", vipTierWatch)
    //     if (vipTierWatch === true){
    //         setValue("deposit_tier", "vip")
    //         setValue("withdraw_tier", "vip")
    //     }else{
    //         setValue("deposit_tier", "normal")
    //         setValue("withdraw_tier", "normal")
    //     }
    //
    // },[watch("vip_tier")])

    const copyObjectIdOnly = ( id ) => {
        navigator.clipboard.writeText( id )
        dispatch( {
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `Copy ID`,
                message: `คัดลอก ${id} สำเร็จ`,
            }
        } );
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Form.Group as={Col} md="3" controlId="validationFormik01">
                    <Form.Label className={"justify-content-center"}>User ID{' '}
                        <OverlayTrigger
                            key={"user-id-tip"}
                            placement={"bottom"}
                            overlay={
                                <Tooltip id={`tooltip-id`}>
                                    <strong>{id}</strong>
                                </Tooltip>
                            }
                        >
                            <Button variant={"outline-primary"} size={"sm"} onClick={()=>copyObjectIdOnly(id)}>{id.substring(0, 6)}...</Button>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="user_id"
                        readOnly={(login_profile.role !== "super_admin" && login_profile.role !== "admin")}
                        placeholder="ยูฟ่า"
                        // defaultValue={first_name}
                        ref={register({ required: !retryFlag })}

                        isInvalid={!!errors.user_id}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุ user id
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" >
                    <Form.Label>Agent Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="agent_name"
                        placeholder="ยูฟ่า"
                        ref={register()}
                        isInvalid={!!errors.agent_name}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" >
                    <Form.Label>Agent Username</Form.Label>
                    <Form.Control
                        type="text"
                        name="agent_username"
                        placeholder=""
                        ref={register()}
                        isInvalid={!!errors.agent_username}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md="3">
                    <Form.Label>วันที่สมัคร</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="created_at"
                        readOnly
                        placeholder="DD/MM/YYYY"
                        defaultValue={
                            (created_at)?
                                moment(created_at).format('DD/MM/YYYY HH:mm:ss')
                                :("")
                        }
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" controlId="validationFormik02">
                    <Form.Label>ชื่อ</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="first_name"
                        // value={first_name}
                        // onChange={handleChange}
                        placeholder="ยูฟ่า"
                        // defaultValue={first_name}
                        ref={register({ required: true })}
                        isInvalid={!!errors.first_name}
                        // isValid={touched.firstName && !errors.firstName}
                    />
                    {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณากรอกชื่อ
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik03">
                    <Form.Label>นามสกุล</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="last_name"
                        placeholder="ฟีเวอร์"
                        // defaultValue={last_name}
                        ref={register({ required: true })}
                        isInvalid={!!errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณากรอกนามสกุล
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik04">
                    <Form.Label>เบอร์โทรศัพท์</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="eg. 081xxxxxxx"
                        name="mobile_no"
                        // defaultValue={mobile_no}
                        //value={mobile_no}
                        ref={register({
                            required: true,
                            pattern: /^\d{10}$/
                        })}
                        isInvalid={!!errors.mobile_no}
                        // onChange={handleChange}
                        // isInvalid={!!errors.mobileNo}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุเบอร์โทรศัพท์
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik05">
                    <Form.Label>LineID</Form.Label>
                    <Form.Control
                        //value={line_id}
                        type="text"
                        placeholder="eg. @feverline"
                        name="line_id"
                        // defaultValue={line_id}
                        ref={register({ required: true })}
                        isInvalid={!!errors.line_id}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุ Line ID
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" controlId="validationFormik06">
                    <Form.Label>เลือกธนาคารหลัก</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="main_bank_short_name"
                                  defaultValue={main_bank_short_name}
                                  custom
                                  ref={register({ required: true })}
                                  isInvalid={!!errors.main_bank_short_name}
                        //readOnly={login_profile.role === "normal"}
                    >
                        <option value="">Please Select</option>
                        {
                            getBankSelectFromRole(main_bank_short_name, "main_bank_short_name",login_profile.role)
                        }
                    </Form.Control>
                    {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกธนาคารหลัก
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik07">
                    <Form.Label>เลขที่บัญชีธนาคารหลัก</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="main_bank_account_no"
                        placeholder="eg. 089XXXXXXX"
                        ref={register({
                            required: true,
                            pattern: /^\d{10,}$/
                        })}
                        isInvalid={!!errors.main_bank_account_no}
                        readOnly={login_profile.role === "normal"}
                        defaultValue={main_bank_account_no}
                    />
                    {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณากรอกเลขที่บัญชีหลัก
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" controlId="validationFormik08">
                    <Form.Label>เลือกธนาคารสำรอง 1</Form.Label>
                    <Form.Control as="select"
                                  name="sub1_bank_short_name"
                        //value={sub1_bank_short_name}
                                  defaultValue={sub1_bank_short_name}
                                  ref={register}
                                  readOnly={login_profile.role === "normal" && sub1_bank_short_name !== undefined}
                                  custom
                    >
                        <option value="">Please Select</option>
                        {
                            getBankSelectFromRole(sub1_bank_short_name, "sub1_bank_short_name", login_profile.role)
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik09">
                    <Form.Label>เลขที่บัญชีธนาคารสำรอง 1</Form.Label>
                    <Form.Control
                        type="text"
                        name="sub1_bank_account_no"
                        // value={sub1_bank_account_no}
                        // onChange={handleChange}
                        defaultValue={sub1_bank_account_no}
                        placeholder="eg. 089XXXXXXX"
                        ref={register({
                            pattern: /^\d{10,}$/,
                            required: watch("sub1_bank_short_name") !== "",
                        })}
                        isInvalid={!!errors.sub1_bank_account_no}
                        readOnly={login_profile.role === "normal" && sub1_bank_account_no !== undefined}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณากรอกเลขที่บัญชีสำรอง 1
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik10">
                    <Form.Label>เลือกธนาคารสำรอง 2</Form.Label>
                    <Form.Control as="select"
                                  name="sub2_bank_short_name"
                        //value={sub2_bank_short_name}
                        // onChange={handleChange}
                                  defaultValue={sub2_bank_short_name}
                                  custom
                                  ref={register}
                                  readOnly={login_profile.role === "normal" && sub2_bank_short_name !== undefined}
                    >
                        <option value="">Please Select</option>
                        {
                            getBankSelectFromRole(sub2_bank_short_name, "sub2_bank_short_name", login_profile.role)
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik11">
                    <Form.Label>เลขที่บัญชีธนาคารสำรอง2</Form.Label>
                    <Form.Control
                        type="text"
                        name="sub2_bank_account_no"
                        // value={sub2_bank_account_no}
                        defaultValue={sub2_bank_account_no}
                        readOnly={login_profile.role === "normal" && sub2_bank_short_name !== undefined}
                        // onChange={handleChange}
                        placeholder="eg. 089XXXXXXX"
                        ref={register({
                            pattern: /^\d{10,}$/,
                            required: watch("sub2_bank_short_name") !== "",
                        })}
                        isInvalid={!!errors.sub2_bank_account_no}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณากรอกเลขที่บัญชีสำรอง 2
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" controlId="validationFormik12">
                    <Form.Label>ช่องทางการสมัคร</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="channel_select"
                        //value={channel_select}
                                  ref={register}
                                  custom
                                  defaultValue={channel_select}
                    >
                        <option value="">Please Select</option>
                        {
                            channelOptions.map(({ innerText, value }, index) =>
                                <option
                                    key={index}
                                    value={value}
                                    //selected={value === channel_select? true : false}
                                >
                                    {innerText}
                                </option>
                            )
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik13">
                    <Form.Label>Reference UserID</Form.Label>
                    <Form.Control
                        type="text"
                        name="reference_user_id"
                        //value={reference_user_id}
                        defaultValue={reference_user_id}
                        ref={register}
                        onChange={setValue("reference_user_id", reference_user_id)}
                        placeholder="eg. ufyu888xxxxx"
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik14">
                    <Form.Label>หมายเหตุ</Form.Label>
                    <Form.Control
                        type="text"
                        name="comment"
                        //value={reference_user_id}
                        defaultValue={comment}
                        ref={register}
                        placeholder="eg. ลูกค้าน่าสงสัย"
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="12" lg="12" controlId="validation26">
                    <Form.Label>NOTE</Form.Label>
                    <Form.Control
                        type="text"
                        as="textarea" rows={3}
                        name="note"
                        //value={reference_user_id}
                        defaultValue={note}
                        ref={register}
                        placeholder="eg. เกมที่ชอบเล่น ข้อมูลติดต่อ"
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="12" lg="12" controlId="validation25">
                    {/*{*/}
                    {/*    selectAgentList()*/}
                    {/*}*/}
                    <RegisterOtherAgentsPanel id={id} allow_add_agents={allow_add_agents} closeModal={onClose}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="4" lg="4" controlId="validation22">
                    {/* <Form.Label>เปิด/ปิดการทำงาน</Form.Label> */}
                    <InputGroup>
                        <Form.Check
                            type="switch"
                            name="credit_auto"
                            custom
                            label="เปิด/ปิดการทำงานออโต้"
                            default={credit_auto}
                            ref={register}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาเลือกสถานะเปิด/ปิด
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4" lg="4" controlId="validation23">
                    <InputGroup>
                        <Form.Check
                            type="switch"
                            name="vip_tier"
                            custom
                            label="เปิด/ปิดลูกค้า VIP"
                            default={vip_tier}
                            ref={register}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาเลือกสถานะเปิด/ปิด
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4" lg="4" controlId="validation24">
                    <InputGroup>
                        <Form.Check
                            type="switch"
                            name="verified"
                            custom
                            label="บัญชีได้รับการยืนยัน"
                            default={verified}
                            ref={register}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาเลือกสถานะการยืนยันบัญชี
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            {/*<Form.Row>*/}
            { buttonIsLoading
                ? ( <Button>
                    <i className="fas fa-circle-notch fa-spin"/> ระบบกำลังดำเนินการ
                </Button>)
                : <>
                    {/*{*/}
                    {/*    registerOtherAgent.register_agent_flag === true && status !== "failed"*/}
                    {/*        ?*/}
                    {/*        (<Button type="button" variant="success" onClick={() => handleRegisterOtherAgent()}>*/}
                    {/*            สมัคร {registerOtherAgent.button_agent}*/}
                    {/*        </Button>)*/}
                    {/*        :(<></>)*/}
                    {/*}*/}
                    {'  '}
                    {
                        renderButtonPanel(status)
                    }
                    {'  '}<Button variant="secondary" onClick={onClose}>
                    ปิด
                </Button>
                </>
            }
            {/*</Form.Row>*/}
        </Form>
    )
}
